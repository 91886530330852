import {Controller} from 'stimulus'

export default class extends Controller
  @targets = [
    'emailInput'
    'submitButton'
  ]

  connect: ->

  handleSubmit: (e) ->