import {Controller} from 'stimulus'

export default class extends Controller
  @targets = [
    'onlyfansIdInput'
    'usernameInput'
    'emailInput'
    'detectedEmailInput'
    'submitButton'
    'period'
    'sourceInput'
    'plans'
    'plan'
    'planInput'
    'form'
    'emailInputTemplateContainer'
  ]

  connect: ->
    if @element.dataset.defaultPlan?
      if @planExists(@element.dataset.defaultPlan)
        @selectPlan(@element.dataset.defaultPlan)
      else
        @selectPlan('monthly')
    else
      @selectPlan('monthly')

    @formTarget.addEventListener 'submit', @handleSubmit

  addAnotherEmail: (e) ->
    e.preventDefault()

    clone = @emailInputTemplateContainerTarget.cloneNode(true)
    clone.removeAttribute('data-checkout-target')
    clone.classList.add 'js-email-input-container'
    clone.classList.remove 'hidden'

    input = clone.querySelector('.js-email-input')
    input.dataset.checkoutTarget = 'emailInput'
    input.setAttribute 'name', 'emails[]'

    # Append after latest .js-email-input-container
    @emailInputTargets
      .slice(-1)[0]
      .closest('.js-email-input-container')
      .insertAdjacentElement('afterend', clone)

  removeEmail: (e) ->
    e.preventDefault()
    e.currentTarget.closest('.js-email-input-container').remove()

  planExists: (code) -> @plansTarget.querySelector("[data-code='#{code}']")?
  selectPlan: (code) -> 
    @chosenPlanCode = code
    
    @planInputTarget.value = @chosenPlanCode

    @planTargets.map (element) => element.classList.remove 'active'
    
    planElement = @planTargets.find (element) => element.dataset.code == @chosenPlanCode
    return alert("Invalid plan") unless planElement

    planElement.classList.add 'active'

    @chosenPlanName = planElement.dataset.name
    @chosenPlanPrice = parseFloat(planElement.dataset.price)

  handlePlanClick: (e) ->
    @selectPlan(e.currentTarget.dataset.code)

  plan: -> @chosenPlanCode
  emails: -> 
    @emailInputTargets
      .map (el) => el.value
      .filter (value) => value.length > 0
      .join ','

  detectedEmail: -> @detectedEmailInputTarget.value
  sourceValue: -> @sourceInputTarget.value

  handleSubmit: (e) =>
    e.preventDefault()

    return alert("Enter at least one OnlyFans account email") if @emails().length == 0

    payload = {
      checkout: {
        username: @usernameInputTarget.value
        onlyfans_id: @onlyfansIdInputTarget.value
        detected_email: @detectedEmail()
        emails: @emails()
        plan: @plan()
        source: @sourceValue()
      }
    }

    @submitButtonTarget.disabled = true

    fetch(@element.dataset.submitUrl, {
      method: 'POST'
      headers: {
        'Content-Type': 'application/json'
      }
      body: JSON.stringify(payload)
    })
    .then (response) => response.json()
    .then (data) => @handleSuccess(data)
    .catch (error) => @handleError(error)

  handleSuccess: (data) ->
    Turbo.visit(data.url)

  handleError: (err) ->
    console.log("ERROR ", err)
    @submitButtonTarget.disabled = false