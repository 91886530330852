import 'simplycountdown.js/css/simplyCountdown.theme.default.css'
import '../vendor/simplyCountdown.custom'

document.addEventListener 'turbo:load', () =>
  countdownEl = document.getElementById('js-checkout-countdown-value')
  return unless countdownEl

  date = new Date(countdownEl.dataset.at)

  year = date.getFullYear()
  month = date.getMonth() + 1
  day = date.getDate()
  hours = date.getHours()
  minutes = date.getMinutes()
  seconds = date.getSeconds()

  countdownEl.innerHTML = ""
  simplyCountdown(countdownEl, {
    year
    month
    day
    hours
    minutes
    seconds
    inline: true
  })