import "stylesheets/vendor" # Base Tailwind styles
import "stylesheets/followbot" # Custom styles

import ahoy from "ahoy.js";
window.ahoy = ahoy;

import Turbo from '@hotwired/turbo'

# import ahoy from "ahoy.js";
# window.ahoy = ahoy;

import importAll from "../utilities/importAll"

importAll(require.context("../followbot", true, /\.(js|coffee|js\.erb)$/))
import "followbot_controllers"

import * as Sentry from '@sentry/browser'
import {Integrations} from "@sentry/tracing"

Sentry.init({
  dsn: "https://173d17ac688c4ff9b796343cbbb0d008@o980501.ingest.sentry.io/6446119",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});