import {Controller} from 'stimulus'
import {confetti} from "dom-confetti";

export default class extends Controller
  @targets = [
    'usernameInput'
    'emailInput'
    'planInput'
    'plans'
    'plan'
    'form'
    'cardElement'
    'requestButton'
    'submitButton'
    'confettiPin'
  ]

  chosenPlanCode: null
  chosenPlanName: null
  chosenPlanPrice: null
  
  stripeInitialized: false

  connect: ->
    if @element.dataset.defaultPlan?
      @selectPlan(@element.dataset.defaultPlan)
    else
      @selectPlan('plan3000')
      @expandList()
      
    @initializeStripe()
  
  handlePlanClick: (e) ->
    @selectPlan(e.currentTarget.dataset.code)

  listIsExpanded: -> @plansTarget.classList.contains 'expanded'
  expandList: -> @plansTarget.classList.add 'expanded'
  closeList: -> @plansTarget.classList.remove 'expanded'
  
  username: -> @usernameInputTarget.value
  email: -> @emailInputTarget.value

  selectPlan: (code) -> 
    # If user clicked on current plan, expand the list
    if @chosenPlanCode == code
      if @listIsExpanded() then @closeList() else @expandList()
      return

    @closeList()

    @chosenPlanCode = code
    
    @planInputTarget.value = @chosenPlanCode

    @planTargets.map (element) => element.classList.remove 'active'
    
    planElement = @planTargets.find (element) => element.dataset.code == @chosenPlanCode
    return alert("Invalid plan") unless planElement

    planElement.classList.add 'active'

    @chosenPlanName = planElement.dataset.name
    @chosenPlanPrice = parseFloat(planElement.dataset.price)

    @updateStripeValues()


  # Get Stripe ready
  initializeStripe: ->
    @stripe = new Stripe(STRIPE_PUBLISHABLE_KEY)
    @elements = @stripe.elements()

    @createCardPayment()
    @createPaymentRequestButton()

    @emailInputTarget.addEventListener 'change', () => @updateStripeValues()

    @formTarget.addEventListener 'submit', @handleStripeSubmit.bind(@)

    @stripeInitialized = true

  # Create card input box
  createCardPayment: ->
    @card = @elements.create('card', style: @stripeStyles(), hidePostalCode: false)
    @card.mount('#card-element')

    @card.on 'change', ({error}) =>
      errorsContainer = @stripeErrors()
      if error
        errorsContainer.innerText = error.message
        @submitButtonTarget.disabled = true
      else
        errorsContainer.innerText = ''
        @submitButtonTarget.disabled = false


  # Create google pay / apple pay button
  createPaymentRequestButton: ->
    @paymentRequest = @stripe.paymentRequest(
      country: 'US'
      currency: 'usd'
      total:
        label: @chosenPlanName
        amount: parseInt(@chosenPlanPrice * 100)
      requestPayerEmail: true
      requestPayerName: false
    )

    @prButton = @elements.create('paymentRequestButton', {
      paymentRequest: @paymentRequest
    })

    @paymentRequest.canMakePayment().then (result) =>
      if result
        @prButton.mount('#payment-request-button')
      else
        document.getElementById('payment-request-button').style.display = 'none';

    @paymentRequest.on 'paymentmethod', (ev) =>
      @stripe.confirmCardPayment(@stripeClientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      ).then (confirmResult) =>
        if confirmResult.error
          ev.complete('fail')
        else
          ev.complete('success')

          if confirmResult.paymentIntent.status == "requires_action"
            @stripe.confirmCardPayment(@stripeClientSecret).then (result) => @handleCardResult(result)
          else
            @handleSuccess()

  # Update stripe values on email / plan change
  updateStripeValues: ->
    return unless @stripeInitialized

    @paymentRequest.update(
      total:
        label: @chosenPlanName
        amount: parseInt(@chosenPlanPrice * 100)
    )

  handleStripeSubmit: (e) ->
    e.preventDefault()
    @submitButtonTarget.disabled = true
    @stripeErrors().innerText = ''

    # Request client secret
    data = 
      checkout:
        email: @email()
        username: @username()
        plan: @chosenPlanCode

    fetch(@element.dataset.secretUrl, 
      method: 'POST'
      headers: 'Content-Type': 'application/json'
      body: JSON.stringify(data)
    )
      .then (res) => res.json()
      .then (json) => 
        stripeClientSecret = json.client_secret

        @stripe.confirmCardPayment(stripeClientSecret, 
          payment_method:
            card: @card
            billing_details:
              email: @email()
          setup_future_usage: 'off_session'
        ).then (result) => @handleCardResult(result)

  handleCardResult: (result) ->
    @submitButtonTarget.disabled = false

    if result.error
      @stripeErrors().innerText = result.error.message
    else if result.paymentIntent.status == 'succeeded'
      @handleSuccess()

  stripeErrors: -> document.getElementById('stripe-errors')
  stripeSuccess: -> document.getElementById('stripe-success')
  handleSuccess: ->
    @stripeSuccess().innerText = "Thanks, your payment was successful! Your credits will be available soon..."
    @showConfetti()
    setTimeout(=>
      # Turbo.visit(@element.dataset.successUrl)
    , 1000)

  showConfetti: ->
    confetti(@confettiPinTarget)

  stripeStyles: ->
    base:
      color: '#454f5b'
      fontFamily: '"-apple-system", "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif'
      fontSmoothing: 'antialiased'
      fontSize: '17px'
      '::placeholder': color: '#637381'
    invalid:
      color: '#212b36'
      iconColor: '#bf0812'