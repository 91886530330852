import {Controller} from 'stimulus'

export default class extends Controller
  @targets = [
    'linkInput'
  ]

  copyLink: (e) -> 
    e.preventDefault()
    btn = e.currentTarget
    @linkInputTarget.select()
    document.execCommand 'copy'

    btn.querySelector('.default').classList.add 'hidden'
    btn.querySelector('.copied').classList.remove 'hidden'

    setTimeout => 
      btn.querySelector('.default').classList.remove 'hidden'
      btn.querySelector('.copied').classList.add 'hidden'
    , 2000