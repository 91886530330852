import {Controller} from "stimulus"

export default class extends Controller
  @targets = ['additionalReasonContainer', 'additionalReasonInput', 'submitButton']

  connect: ->
    $(@element).find("input[type=radio]").on 'change', (e) =>
      trigger = e.currentTarget
      if trigger.dataset.requiresAdditional == 'false' or trigger.dataset.requiresAdditional == undefined
        @hideAdditionalReason()
        @submitButtonTarget.disabled = false
      else
        placeholder = trigger.dataset.requiresAdditional
        placeholder = null if placeholder == 'true' or placeholder == 'null'
        @showAdditionalReason(placeholder)
        @submitButtonTarget.disabled = true

    $(@additionalReasonInputTarget).on 'input', (e) =>
      # Check if currently selected $(@element).find("input[type=radio]") has data-requires-additional set to false
      # If so, enable the submit button
      
      trigger = $(@element).find("input[type=radio]:checked")[0]
      if trigger.dataset.requiresAdditional == 'false' or trigger.dataset.requiresAdditional == undefined
        @submitButtonTarget.disabled = false
      else
        # Additional reason is required
        if e.currentTarget.value.toString().trim().length > 0
          @submitButtonTarget.disabled = false
        else
          @submitButtonTarget.disabled = true


  showAdditionalReason: (placeholder) ->
    @additionalReasonContainerTarget.classList.remove 'hidden'
    @additionalReasonInputTarget.placeholder = placeholder or "Please specify"
    @additionalReasonInputTarget.required = true

  hideAdditionalReason: ->
    @additionalReasonContainerTarget.classList.add 'hidden'
    @additionalReasonInputTarget.placeholder = "Please specify"
    @additionalReasonInputTarget.required = false